import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

const CustomSsnInput = (props) => {
  const { id, name, value, required, onChange, disabled, placeholder } = props

  const [rawValue, setRawValue] = useState(value || '');
  const [hasFocus, setHasFocus] = useState(false);
  
  const s = "-";

  const handleChange = (e) => {
    let newValue = e.target.value;

    // handle backspace at dash positions
    if (
      rawValue.substr(-1) === s &&
      newValue === rawValue.substring(0, rawValue.length - 1)
    ) {
      newValue = rawValue.substring(0, rawValue.length - 2)
      setRawValue(newValue);
      return;
    }

    // strip out non-numeric characters
    newValue = newValue.replace(/[^0-9]/g, "");
    // insert dashes & limit to 9 characters
    if (newValue.length > 2 && newValue.length < 5) {
      newValue = newValue.substring(0, 3) + s + newValue.substring(3);
    } else if (newValue.length >= 5) {
      newValue =
        newValue.substring(0, 3) +
        s +
        newValue.substring(3, 5) +
        s +
        newValue.substring(5, 9);
    }

    setRawValue(newValue);
    onChange({'target': { name, value: newValue }});
  }

  const handleFocus = () => {
    setHasFocus(true);
  };

  const handleBlur = () => {
    setHasFocus(false);
  };

  const toggleFocus = () => {
    setHasFocus(!hasFocus);
  }

  const getDisplayValue = () => {
    if (hasFocus || !rawValue) {
      return rawValue;
    }
    
    // Mask first 5 digits, leaving last 4 visible
    const parts = rawValue.split(s);
    const maskedValue = parts.length === 3 
      ? `●●●${s}●●${s}${parts[2]}` 
      : rawValue.replace(/[0-9]/g, "●").slice(0, rawValue.length - 4) + rawValue.slice(-4);
    
    return maskedValue;
  };

  let className = '!mt-0 custom-text-input';
  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <div className="relative w-full mt-2">
      <input
        name={name}
        id={id}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        className={className}
        value={getDisplayValue()}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputMode="numeric"
        maxLength={11}
      />
      <button
        type="button"
        onClick={toggleFocus}
        className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500"
        tabIndex={-1}
      >
        {hasFocus ? (
          <EyeSlashIcon className="h-4 w-4" title="Hide full SSN" />
        ) : (
          <EyeIcon className="h-4 w-4" title="Show full SSN" />
        )}
      </button>
    </div>

  );
};

export default CustomSsnInput;
