// components/inputs/CustomRjsfInputs.js
import CustomInput from './CustomInput';
import CustomLabel from './CustomLabel';
import CustomSelect from './CustomSelect';
import CustomCheckboxGroup from './CustomCheckboxGroup';
import CustomCheckbox from './CustomCheckbox';
import CustomButton from '../buttons/CustomButton';
import InputErrors from './InputErrors';
import CustomTextarea from './CustomTextarea';
import CustomRadioGroup from './CustomRadioGroup';
import CustomPhoneInput from './CustomPhoneInput';
import CustomSsnInput from './CustomSsnInput';
import Markdown from 'react-markdown';
import { Autocomplete } from '@react-google-maps/api';
import { getAddressFromGooglePlace } from '../../utils/address';
import React, {  useState } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid'


export const CustomErrorListTemplate = (props) => {
  const { errors } = props;
  return <InputErrors errorMessages={errors} />;
}

export const CustomFieldTemplate = (props) => {
  const { id, label, help, description, errors, children, hidden, displayLabel, uiSchema } = props;
  return (
      <div className={hidden ? 'hidden' : uiSchema && uiSchema['ui:noBottomMargin'] ? '' : '[&:not(:last-child)]:mb-5'}>
        {displayLabel &&
          <CustomLabel htmlFor={id} tooltip={help && help.props && help.props.help ? help.props.help : null}>
            {label}
          </CustomLabel>
        }
        {description}
        {children}

        {errors?.props?.errors && errors.props.errors.length > 0 && <InputErrors errorMessages={errors.props.errors} />}
      </div>
  )
};

export const CustomArrayFieldItemTemplate = (props) => {
  const { children, hasRemove, disabled, onDropIndexClick, index } = props;

  const bgClass = props.canAdd ? 'bg-fabric-pink-range-50': 'bg-gray-100';

  return (
    <div className={`px-6 py-4 ${bgClass} mt-6 first:mt-3 rounded border border-gray-200`}>
      {children}
      {hasRemove && <div className='text-right'>
        <CustomButton className='text-xs' onClick={onDropIndexClick(index)} disabled={disabled}>
          <MinusIcon className="h-4 w-4 inline align-text-bottom" /> Remove
        </CustomButton>
      </div>}
    </div>
  )
};

export const CustomTitleFieldTemplate = (props) => {
  return (
    <CustomLabel htmlFor={props.id}>
      {props.title} 
    </CustomLabel>
  )
};

export const ArrayAddButton = (props) => {
  const { className, onClick, disabled, uiSchema } = props;

  const combinedClassNames = className + ' text-xs mt-4';

  const buttonLabel = uiSchema['ui:addButtonLabel'] ? uiSchema['ui:addButtonLabel'] : 'Add';

  return (
    <CustomButton className={combinedClassNames} onClick={onClick} disabled={disabled}>
      <PlusIcon className="h-4 w-4 inline align-text-bottom" /> {buttonLabel}
    </CustomButton>
  );
}

export const CustomInputTemplate = (props) => {
  const { id, className, placeholder, style, value, onChange, name, pattern, type, required, readonly, disabled, uiSchema } = props;
  const handleChange = event => {
    let selectedValue = event.target.value;
    if (selectedValue?.trim() === '') {
      // treat empty string (or all whitespace) as undefined, which makes the 'required' handling in RJSF behave sanely
      selectedValue = undefined;
    }
    onChange(selectedValue);
  };

  let classes = className;
  if (uiSchema['ui:isCurrency']) {
    classes = classes || '' + ' room-for-icon';
  }

  return (
    <div>

      {uiSchema['ui:isCurrency'] && 
      <span className='text-gray-500 inline-block align-middle mb-1'>
        <svg className='size-4' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path fill='currentColor' d="M160 0c17.7 0 32 14.3 32 32l0 35.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11l0 33.4c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-34.9c-.4-.1-.9-.1-1.3-.2l-.2 0s0 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7s0 0 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11L128 32c0-17.7 14.3-32 32-32z"/>
        </svg>
      </span>}

      <CustomInput
        id={id}
        className={classes}
        style={style}
        placeholder={placeholder}
        pattern={pattern}
        value={value}
        onChange={handleChange}
        name={name}
        type={type}
        required={required}
        readOnly={readonly}
        disabled={disabled}
      />
    </div>
  )
}

export const CustomPhoneWidget = ({onChange, ...props}) => {
  const handleChange = value => {
    if (value?.trim() === '') {
      // treat empty string (or all whitespace) as undefined, which makes the 'required' handling in RJSF behave sanely
      value = undefined;
    }    
    onChange(value);
  };
  return <CustomPhoneInput onChange={handleChange} {...props} />;
}

export const CustomSsnWidget = ({onChange, ...props}) => {
  const handleChange = event => {
    let selectedValue = event.target.value;
    if (selectedValue?.trim() === '') {
      selectedValue = undefined;
    }    
    onChange(selectedValue);
  };
  return <CustomSsnInput onChange={handleChange} {...props} />;
}

export const CustomTextareaWidget = (props) => {
  const { id, placeholder, style, value, onChange, name, pattern, required, disabled } = props;
  const handleChange = event => {
    let selectedValue = event.target.value;
    if (selectedValue?.trim() === '') {
      // treat empty string (or all whitespace) as undefined, which makes the 'required' handling in RJSF behave sanely
      selectedValue = undefined;
    }    
    onChange(selectedValue);
  };
  return <CustomTextarea
    id={id}
    style={style}
    placeholder={placeholder}
    pattern={pattern}
    value={value}
    onChange={handleChange}
    name={name}
    required={required}
    disabled={disabled}
  />;
}


export const CustomSelectWidget = (props) => {
  const { options, onChange, id, name, label, required, placeholder, disabled, value } = props;
  const handleChange = event => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };
  return (
    <CustomSelect onChange={handleChange} id={id} name={name} label={label} required={required} placeholder={placeholder} disabled={disabled} value={value}>
      <option disabled selected value=''>Select an option</option>
      {options.enumOptions && options.enumOptions.map(option => (
        <option key={option.value}
          value={option.value}>
          {option.label}
        </option>
      ))}
    </CustomSelect>
  );
};

export const CustomCheckboxWidget = (props) => {
  const { value, onChange, name, label, disabled, id } = props;
  const handleChange = value => {
    const selectedValue = value;
    onChange(selectedValue);
  }
  return (
    <CustomCheckbox value={value} name={name} label={label} onChange={handleChange} disabled={disabled} id={id} />
  )
}

export const CustomRadioGroupWidget = (props) => {
  const { options, value, onChange, required, name, disabled, id } = props;
  const handleChange = value => {
    const selectedValue = value;
    onChange(selectedValue);
  };
  return (
    <CustomRadioGroup options={options.enumOptions} value={value} required={required} name={name} onChange={handleChange} disabled={disabled} id={id}>
    </CustomRadioGroup>
  )
}

export const CustomCheckboxGroupWidget = props => {
  const { id, value, onChange, options, name, disabled } = props;
  const handleChange = updatedValue => {
    onChange(updatedValue);
  };

  return (
    <CustomCheckboxGroup id={id} options={options.enumOptions} value={value} name={name} onChange={handleChange} disabled={disabled} />
  );
};

export const CustomMarkdownWidget = (props) => {
  const { value, linksInSameTab, id } = props;

  return (
    <div className='custom-markdown' id={id}>
      <Markdown components={
        { a(props) { return <LinkRenderer href={props.href} children={props.children} newTab={linksInSameTab !== true} /> }}
      }>
        {value}
      </Markdown>
    </div>
  );
};

export const CustomAddressWidget = (props) => {
  const { value, id, name, disabled, required, placeholder, onChange, rawErrors } = props;
  const [autocompleteState, setAutocompleteState] = useState(null);
  const [autocompleteVal, setAutocompleteVal] = useState(
    (value && value['address']) || ''
  );

  const handleAutocompleteLoad = (autoComp) => {
    setAutocompleteState(autoComp);
  };

  const handleChange = updatedValue => {
    setAutocompleteVal(updatedValue);

    if(!!!updatedValue){
      const formData = {}; 
      formData['address'] = '';
      formData['street'] = '';
      formData['city'] = '';
      formData['state'] = '';
      formData['zip'] = '';

      if (rawErrors) rawErrors.length = 0; // HACK: kill any existing validation errors
      onChange(formData);
      hideSpinner();
    }
    else {
      const formData = {};
      formData['address'] = updatedValue;
      onChange(formData);
      
      showSpinner();
    }
  };

  const showSpinner = () => {
    const el = document.getElementById('addressLoadingSpinner');
    el.classList.remove('hidden');
  }

  const hideSpinner = () => {
    const el = document.getElementById('addressLoadingSpinner');
    el.classList.add('hidden');
  }

  const handlePlaceSelect = () => {
    if (autocompleteState !== null) {
      const place = autocompleteState.getPlace();
      const googleAddress = getAddressFromGooglePlace(place);
      handleChange(place.formatted_address)
      
      const formData = {};
      formData['address'] = place.formatted_address;
      formData['city'] = googleAddress.city;
      formData['state'] = googleAddress.state;
      formData['zip'] = googleAddress.zip;

      if (!!googleAddress.houseNum && !!googleAddress.streetName) {
        formData['street'] = `${googleAddress.houseNum} ${googleAddress.streetName}`; 
      }
      else {
        formData['street'] = '';
      }
      
      onChange(formData);
      hideSpinner();
    }
  };

  return (
    <div className="flex items-center">
      <Autocomplete
        onLoad={handleAutocompleteLoad}
        onPlaceChanged={handlePlaceSelect}
        className="grow"
      >
        <CustomInput
          type="text"
          
          value={autocompleteVal}
          onChange={(e) => handleChange(e.target.value)}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          name={name}
        />
      </Autocomplete>
      <div id="addressLoadingSpinner" className="hidden ml-2 mt-2">
        <svg width="20" height="20" viewBox="0 0 50 50">
          <circle cx="25" cy="25" r="20" stroke="black" strokeWidth="4" fill="none" strokeDasharray="31.4" strokeLinecap="round">
            <animateTransform attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1s" repeatCount="indefinite" />
          </circle>
        </svg>
      </div>
    </div>
  );
};


const LinkRenderer = ({ href, children, newTab }) => {
  return (
    <a href={href} target={newTab ? "_blank" : ""} rel="noopener noreferrer">
      {children}
    </a>
  )
};