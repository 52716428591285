export const UserLoginState = {
  UNKNOWN: 'UNKNOWN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  LOGGED_IN: 'LOGGED_IN'
}

export const MemberDashboardMessages = {
  RELOAD_PARENT: 'RELOAD_PARENT',
  IS_SAVING: 'IS_SAVING',
  ERROR_SAVING: 'ERROR_SAVING',
}

export const Stage = {
  LOCAL: 'local',
  DEV: 'dev',
  PROD: 'prod'
}